<template>
  <div class="top-bar">
    <v-container>
      <v-row>
        <v-col cols="10" class="pl-0 py-0">
          <v-btn
            icon
            depressed
            @click="$emit('go-back-action')"
          >
            <v-icon>{{back_arrow}}</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="2" class="text-right pr-0 py-0">
          <v-btn
            icon
            class="menu-btn"
            @click="$emit('show-nav-drawer')"
          >
            <v-icon size="18">
              {{menu_icon}}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'CheckoutTopBar',
    data: () => ({
      menu_icon: "$vuetify.icons.menu_icon",
      nav_icon: "$vuetify.icons.nav_icon",
      back_arrow: "$vuetify.icons.back_arrow",
    })
  };
</script>

<style type="text/css">
  .top-bar {
    position: fixed;
    width: inherit;
    max-width: inherit;
    margin-top: -20px;
    margin-left: -20px;
    padding: 8px 20px 0;
    background: #ffffff;
    z-index: 100;
  }

  .nav-icon {
    margin-right: 4px;
  }

  .down-arrow {
    margin: 2px 0 0 20px;
  }

  #my-app .address-btn {
    padding: 0;
    align-items: flex-start;
  }

  #my-app .address-btn.v-btn::before,
  #my-app .address-btn.v-btn::after {
    display: none;
  }
</style>
