<template>
  <v-container id="my-orders">
    <CheckoutTopBar
      @go-back-action="goToMainFeed"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <v-row class="mb-4">
        <v-col cols="12">
          <div class="title-10">
            Support
          </div>
        </v-col>
      </v-row>

      <v-list flat id="support-list">
        <v-list-item
          class="support-item"
          href="https://wa.me/306972992817"
        >
          <v-list-item-icon>
            <v-img
              src="@/assets/whatsapp_icon.png"
              width="50"
              height="50"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="text-left py-2">
            <v-list-item-title>
              <div class="text-uppercase title-15 mb-1">
                Open WhatsApp chat
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div class="text-uppercase body-3 mb-1">
                +30 697 299 2817
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-divider mb-4"></v-divider>

        <v-list-item
          class="support-item"
          href="https://l1nq.link/?viber=306972992817"
        >
          <v-list-item-icon>
            <v-img
              src="@/assets/viber_icon.png"
              width="50"
              height="50"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="text-left py-2">
            <v-list-item-title>
              <div class="text-uppercase title-15 mb-1">
                Open Viber chat
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div class="text-uppercase body-3 mb-1">
                +30 697 299 2817
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-divider mb-4"></v-divider>

        <v-list-item
          class="support-item"
          href="tel:2106420888"
        >
          <v-list-item-icon>
            <v-img
              src="@/assets/phone_icon.png"
              width="50"
              height="50"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="text-left py-2">
            <v-list-item-title>
              <div class="text-uppercase title-15 mb-1">
                Call us on the phone
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div class="text-uppercase body-3 mb-1">
                +30 210 64 20 888
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <SignInModal
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />
    
    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
      @open-sign-in-modal="openSignInModal"
    />
  </v-container>
</template>

<script>
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'Support',
    components: {
      CheckoutTopBar,
      NavigationDrawer,
      SignInModal
    },
    data: () => ({
      showNavDrawer: false,
      showSignInModal: false,
    }),
    methods: {
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      },
    }
  };
</script>

<style type="text/css">
  .support-item {
    cursor: pointer;
  }
</style>
